/* - WEB VERSION - */
@media only screen and (min-width: 768px){

  .panel{
    flex-flow: column;
    justify-content: center;
    background-color: #F2F2F2;
    min-height: 30rem;
    font-family:'rubikregular';
    max-width: 100vw;
    padding-bottom: 20px;
    }

  .panel b {
    font-weight:bold;
  }

  .panel h1{
    font-size:  xxx-large;
  }

  .panel  button{
      background-color: orange;
      border: 1px yellow solid;
      border-radius: 20px;
      width: 233px;
      height: 41px;
      font-size: x-large;
    }


  .contactBox{
      background-color:#20375B;
      border-radius: 32px;
      margin:15px auto;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
      width:70%;
      color: white;
      padding:20px;
      font-size: 22px;
      font-family: sans-serif;

      }

      .contactBox h2{
        font-size:21px;
        margin:8px auto;
        flex:1;
        word-wrap: break-word;
        padding: 3px;
      }
    .contactBox .ups{
          margin-top: 12px;
      }
    .contactBox .phnsty{
      font-style: italic;
    }
    .contactBox  button{
        background-color: orange;
        border: 1px yellow solid;
        border-radius: 20px;
        width: 233px;
        height: 41px;
        font-size: x-large;
      }
    .contactdiv{
      word-wrap: break-word;
    }

    .butrow{
      display: flex;
      flex-flow: row-reverse;
    }
    .consp  {
      display:flex;
      flex-flow: row;
       margin:5px auto;
    }


}


/*- MOBILE VERSION -*/

@media (max-width: 768px){


    .contactBox{
      background-color:#20375B;
      border-radius: 32px;
      margin:15px auto;
      margin-bottom: 27px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
      width:90%;
      color: white;
      padding:8px;
      font-size: 12px;
      font-family: sans-serif;
      }

      .contactBox h2{
        margin:8px auto;
        flex:1;
        word-wrap: break-word;
        padding: 3px;
        letter-spacing: 0.246063px;
      }
      .contactBox .ups{
            /* margin-left: 12px; */
        }
        .contactBox .phnsty{
          font-style: italic;

        }
        .contactBox  button{
            background-color: orange;
            border: 1px yellow solid;
            border-radius: 20px;
            width: 233px;
            height: 41px;
            font-size: x-large;
          }

          .contactdiv{
            word-wrap: break-word;
          }

          .butrow{
            display: flex;
            flex-flow: row-reverse;
          }
          .consp  {
            display:flex;
            flex-flow: column;
          }




}
