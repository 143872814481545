/*
******* CSS RESET *****
*/

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
 /*
****** END OF RESET *****
  */

	.paraBlox{
    width:-webkit-fill-available;
    min-height:50px;
    background-color: white;
		box-sizing: border-box;
  }

/* - WEB STYLE - */
@media only screen and (min-width:768px){
	.App{
		display:flex;
		flex-flow: column;
		width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
		height: auto;
		background-color: #EBEBEB;
	}
	/* HEADER COMPONENT */
	.Nav{
		top:0;
		left:0;
		align-items: flex-start;
		position: fixed;
		background-color: #20375b;
		/* border: red 2px solid; */
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		vertical-align: center;
		height: 50px;
		width: 100%;
		width: -moz-available;          /* WebKit-based browsers will ignore this. */
		width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
		width: stretch;
		font-family: 'rubikregular';
		z-index: 9999;
	}
	.spacer {
		height: 50px;
	}
	.Nav a{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		margin-top: 20px;
		text-decoration: none;
		font-size: 20px;
		font-weight: bold;
		color:white;
	}

	.Nav a:hover{
		/* font-size: 20px; */
		color:orange;
		transition: 1s;
	}
	/**REMEMBER THIS FOR THE WEB VERSION**/

	/*
 ****** END OF header *****
	 */

	 /*FOOTER COMPONENT*/
	 .Footer{
		 display:flex;
		 justify-content: space-evenly;
		 align-items:center;
		 bottom:0px;
		 left:0px;
		 position: fixed;
		 background-color: #424c55;
		 color:white;
		 font-family: 'rubikregular';
		 /* border: yellow solid 2px; */
		width: 100%;
		width: -moz-available;          /* WebKit-based browsers will ignore this. */
		width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
		width: stretch;
		height:30px;
		z-index: 130;

	 }

	 .Footer div{
			 justify-content: space-between;
			 background-color: rgba(10, 10, 10, 0.3);
			 padding:3px;
			 border-radius: 10px;
			 cursor: pointer;
	 }

	 .Footer div:active{
		 background-color: rgba(244, 244, 244, 0.6);
		 color:#424c55;
	 }

	 .Footer p {
		 font-weight:bold;
		 font-size:30px;
	 }

	 .footerswitch div:hover{
		 cursor: pointer;
	 }
	/*VIEW COMPONENT*/
	.viewCont{
		display:flex;
		flex-flow: column;
		width: 100%;
		width: -moz-available;          /* WebKit-based browsers will ignore this. */
		width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
		width: stretch;
		/* height:145vh; */
		/* background-color: blue; */
		margin: 20px auto 0px;
		margin: 0;
		    padding-bottom: 20px;
	}
	/**PARALAX - Styles all paralax items**/
  .paraBlox{
    width:-webkit-fill-available;
    min-height:50px;
    background-color: #EBEBEB;

  }
	/** - PARA INNER - the width and height of the images in Paralaxx -**/
	.paraInner {
		width:-webkit-fill-available;
		height:300px;
	}
	/**PARALAX - Styles all paralax items**/
  /* .paraBlox{
    width:-webkit-fill-available;
    min-height:50px;
    background-color: white;

  }

  .paraBlox > h1{
    padding:10px;
  } */
	/** - PARA INNER - the width and height of the images in Paralaxx -**/

	/** - END OF PARA INNER - **/
	/**END OF PARALAX **/
	/**LOGOS - Styles all Logos**/

	.logo{
		height:50%;
		background-color: #EBEBEB;
	}
	.logo > #longLogo{
		height:auto;
		width:98%;
		margin-top: 1%;
		margin-bottom: -1%;

	}
	.logo #fourBars{
		height:39px;
		width: 100%;

		margin-top: -2%;
	}
}

	/* MOBILE STYLE */
	@media (max-width: 768px) {
		.App{
			display:flex;
			flex-flow: column;
			width: 100%;
	    width: -moz-available;          /* WebKit-based browsers will ignore this. */
	    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
	    width: stretch;
			height: auto;
			background-color:#EBEBEB;
		}
		/* HEADER COMPONENT */
		.Nav{
			min-height: 29px;
			max-height: 39px;
			top:0;
			align-items: flex-start;
			position: fixed;
			background-color: black;
			/* border: red 2px solid; */
			font-family: 'rubikregular';
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			vertical-align: center;
			height: 6%;
			width: 100%;
			width: -moz-available;          /* WebKit-based browsers will ignore this. */
			width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
			width: stretch;
			/* display: flex;
			flex-direction: row;
			justify-content: center; */
			margin-bottom: 20px;
			z-index: 9999;
		}

		.paraBlox{
			margin-top: 5%;
		}
		.spacer{
			display:none;
		}

		.Nav a{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			margin-top: 10px;
			text-decoration: none;
			font-size: 15px;
			font-weight: bold;
			color:white;
		}

		.Nav a:hover{
			/* font-size: 20px; */
			color:orange;
			transition: 1s;
		}
		/**REMEMBER THIS FOR THE WEB VERSION**/

		/*
	 ****** END OF header *****
		 */

		 /*FOOTER COMPONENT*/
		 .Footer{
			 display:flex;
			 justify-content: space-evenly;
			 align-items: center;
			 bottom:0px;
			 left:0px;
			 position: fixed;
			 background-color: #424c55;
			 color:white;
			 font-family: 'rubikregular';
			 /* border: yellow solid 2px; */
				width: 100%;
				width: -moz-available;          /* WebKit-based browsers will ignore this. */
				width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
				width: stretch;
				height:40px;
				z-index: 130;
		 }

		 .Footer div{
				align-items: space-between;
				background-color: rgba(10, 10, 10, 0.3);
				padding:4px;
				border-radius: 10px;
		 }

		 .Footer div:active{
			background-color: rgba(244, 244, 244, 0.6);
			color:#424c55;
		}

		 .Footer p {
			 font-size:20px;
		 }

		 .footerswitch p:hover{
			 cursor: pointer;
		 }
		/*VIEW COMPONENT*/
		.viewCont{
			display:flex;
			flex-flow: column;
			width: 100%;
			width: -moz-available;          /* WebKit-based browsers will ignore this. */
			width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
			width: stretch;
			/* height:100vw; */
			/* background-color: blue; */
			/* margin: 20px auto 0px; */
		}

		.logo{
			height:auto;
			max-width:100%;
		}
	}

@media(min-width: 1396px) {
		.paraBlox{
			margin: 0px auto 12px;
		}
}
